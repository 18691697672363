form.pure-form {
  padding: 10px 10px 10px 0;
}

.button-success,
.button-error,
.button-warning,
.button-secondary {
  color: #333;
  border-radius: 4px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-success {
  background: rgb(217, 247, 190);
  border-color: rgb(197, 226, 170);
  /* this is a green */
}

.button-error {
  background: rgb(234, 184, 179);
  /* this is a maroon */
}

.button-warning {
  background: rgb(234, 211, 166);
  /* this is an orange */
}

.button-secondary {
  background: rgb(66, 184, 221);
  /* this is a light blue */
}

#clearSearch {
  cursor: pointer;
}

/* Search input css start here */
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group input {
  margin-bottom: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
  width: auto !important;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  border: 2px solid #ced4da;
  border-radius: 0.25rem;
  border-left: none !important;
}

.input-group input:focus .input-group-append .input-group-text,
.input-group input:focus .input-group-append .input-group-text {
  border-color: #129fea !important;
}

.input-group input:focus,
.input-group input:focus {
  border-color: #ddd !important;
}

.btn-clr {
  background-color: white;
  color: black;
}

.btn-default {
  border-color: rgb(109, 109, 109) !important;
  color: #333 !important;
}

.btn-default,
.btn-occ,
.btn-exp,
.btn-vac {
  font-size: 0.85rem;
  font-weight: 500;
  border: 1px solid;
  border-radius: 10px !important;
  padding: 3px 8px !important;
  margin: 5px 10px 5px 0;
  width: 80px;
}

.btn-occ {
  color: #fff !important;
  background-color: #587de4 !important; /* #4571ea; */
  border-color: #3b66d9 !important;
}

.btn-exp {
  color: #fff !important;
  background-color: #e96881 !important; /* #e94f6c; */
  border-color: #e23f5d !important;
}

.btn-vac {
  color: #fff !important;
  background-color: #74c6a2 !important; /* #74d4aa */
  border-color: #64c79c !important;
}

@media screen and (max-width: 600px) {
  .btn-default {
    border-color: rgb(255 255 255) !important;
    color: #fff !important;
  }
}
