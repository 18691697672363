html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: manipulation; /* Prevents zooming on tap */
}

/** no gutters in a row */
.no-gutters {
  margin-right: 0;
  margin-left: 0;
  margin-top: 5px;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Container {
  width: 100vw;
  height: 100vh;
  margin: 15px auto;
  padding: 0;
}

.custom-container {
  padding: 1.5em 0em 0em !important;
  background-color: whitesmoke;
}

.custom-container .header {
  display: block;
  width: 100%;
  padding: 40px 0 0;
}

.center {
  text-align: center;
}

.custom-menu {
  padding: 0 !important;
}

.pure-menu-heading,
.pure-menu-link {
  padding: 1em;
}

.logo-img {
  width: 61px;
}

.logo-name {
  display: block;
  text-align: right;
  /* margin: -30px auto 10px; */
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding: 0.79em;
}

.home-menu .pure-menu-selected a {
  border-bottom: 2px solid #fff !important;
  color: white !important;
}

.map-container {
  position: relative;
  /* width: 100vw; */
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
  left: -18px;
  background: whitesmoke;
  border-right: 1px solid #d9c6c6;
}

.map-container-section {
  /* height: 90vh; */
  overflow: hidden;
  position: relative;
  top: 25px;
}

#smplr-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  display: block;
}

.map-container-directory-search {
  position: relative;
  height: 100%;
  left: 1vh;
  bottom: 39vh;
}

.map-filters {
  /* margin-top: 90px; */
  position: relative;
  z-index: 1;
  overflow: auto;
  height: 90vh;
  margin-top: 20px;
}

/* #map-controls {
  position: absolute;
  left: 10%;
  top: 10%;
  padding: 10px;
  z-index: 1;
} */

/* #map-controls {
  position: fixed;
  width: 30%;
  height: 86%;
  z-index: 1;
} */

#map-controls {
  width: 30%;
  z-index: 1;
  position: fixed;
  right: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.section-heading {
  font-size: 1.1rem;
  font-weight: 600;
}

.form-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  padding-left: 0;
}

.dropdown-filters {
  position: relative;
  top: 15px;
}

/* .map-filter-container {
  overflow: auto;
  height: 100vh;
} */

#smplr-container > div > div > div > canvas + div {
  bottom: 6.5rem;
  left: 2.75rem;
}

.text-right {
  text-align: right;
}

/* Sidebar styling */

.sidebar {
  position: relative;
  top: 0;
  right: 10px;
  width: 100%;
  /* Adjust width as needed */
  /* height: 100%; */
  /* overflow-y: auto; */
  cursor: pointer;
}

.logo {
  padding: 10px;
  text-align: center;
  width: 50%;
  display: inline-block;
}

.logo img {
  width: 75px;
  /* Adjust size as needed */
  height: auto;
  border-radius: 6px;
}

.mg-0 {
  margin: 12px;
}

/** dropdown css start here  */

.label-divider {
  margin-bottom: 0.75rem;
}

.section-divider {
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

p {
  margin-bottom: 0;
}

.search-dropdown-container {
  width: 100%;
  display: block;
  margin: auto;
  padding-top: 10px;
}

.logo-icons-container {
  margin-top: 25px;
  height: 59vh;
  /* 90vh; */
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  background: #953e96 !important;
  position: fixed !important;
  bottom: 0px !important;
  width: 100% !important;
  height: 10%;
  color: white;
}

.text-center {
  padding-top: 15px;
}

/* styles.css */
.hamburger {
  display: none;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  font-size: 2em;
  cursor: pointer;
  z-index: 20;
}

.hamburger i.fa {
  font-size: 2.25rem;
  color: #953e96;
}

.hamburger i.fa-bars {
  display: inline-block;
}

/* .hamburger i.fa-times {
  display: none;
} */

.menu_sidebar {
  display: none;
}

.close-menu {
  position: absolute;
  top: 1.25rem;
  left: 1.65rem;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 3px solid;
  text-align: center;
  line-height: 1;
  padding: 1px;
}

.close-menu i.fa-times {
  font-size: 1.8rem;
}

.alignment {
  margin-bottom: none !important;
}

/* Mobile Responsive CSS  */

@media screen and (max-width: 600px) {
  .map-filters {
    display: none;
  }

  .no-gutters {
    margin-right: inherit !important;
    margin-left: inherit !important;
    margin-top: 0;
  }
  .hamburger {
    display: block;
  }

  form.pure-form {
    width: 100%;
    margin-top: 30px;
    overflow: auto;
  }

  .map-container {
    left: -12px;
    width: 100vw;
    height: 100vh;
  }

  #smplr-container {
    width: 100%;
    height: 100vh;
  }

  .text-center {
    padding-top: 35px;
  }

  #smplr-container > div > div > div > canvas + div {
    bottom: 8.5rem;
    left: 0.75rem;
  }

  /* div#stallSearchItems,
  div#searchShopsOnDirectorySearch,
  div#filterByCategory {
     width: 90%;
    width: 86%;
    margin-left: 10%;
  } */

  .menu_sidebar {
    position: absolute;
    /* flex-direction: column; */
    top: 0;
    left: 0;
    /* Hide the sidebar off-screen */
    background-color: rgb(149 62 150 / 90%);
    color: #fff;
    width: 100%;
    overflow: auto;
    height: 100vh;
    padding: 25px;
    box-sizing: border-box;
    z-index: 90;
    transition: display 0.3s ease;
    font-weight: 600;
  }

  .menu_sidebar.active {
    display: block;
    /* Show the sidebar on-screen */
  }

  .menu_sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu_sidebar ul li {
    margin: 20px 0;
  }

  .menu_sidebar ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
  }
  .pure-form label,
  .section-divider {
    color: #fff;
  }

  .select__control,
  .select__control--is-focused {
    background-color: hsl(299 33% 46% / 1) !important;
    border-color: hsl(0deg 0% 100%) !important;
    box-shadow: 0 0 0 1px #e771e9 !important;
  }

  .select__multi-value {
    background-color: hsl(0deg 0% 100%) !important;
    color: #9e509f !important;
  }

  .select__placeholder,
  .select__indicator,
  .select__single-value {
    color: #fff !important;
  }

  .select__menu {
    width: 225px !important;
  }

  .select__menu div {
    background: #9a4c9b !important;
    color: #fff;
    width: 100%;
    overflow: auto;
  }

  .logo-icons-container {
    height: 60vh;
  }

  .logo img {
    width: 100%;
  }
  .search-dropdown-container {
    max-width: 100%;
  }

  .basic-multi-select {
    width: 320px !important;
    max-width: 100%;
  }
  .react-select__control,
  .select__control {
    width: 100% !important; /* Or set a specific width */
    max-width: 100%; /* Ensure it does not exceed its container */
  }

  .react-select__menu,
  .select__menu {
    width: 100% !important; /* Ensures the menu expands to full width */
    max-height: 300px; /* Adjust based on your design */
    overflow-y: auto; /* Allow scrolling if items exceed max-height */
    -webkit-overflow-scrolling: touch;
  }
  .select__input {
    color: #fff !important;
  }
}

@media only screen and (max-width: 480px) {
  .map-filters {
    display: none;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
  .no-gutters {
    margin-right: inherit !important;
    margin-left: inherit !important;
    margin-top: 0;
  }

  .col-lg-9 {
    position: relative;
  }

  .hamburger {
    display: block;
  }

  form.pure-form {
    width: 100%;
    margin-top: 30px;
    overflow: auto;
  }

  .map-container {
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  #smplr-container {
    width: 100%;
    height: 100vh;
  }

  .text-center {
    padding-top: 35px;
  }

  #smplr-container > div > div > div > canvas + div {
    bottom: 10rem;
    left: 0.75rem;
  }

  /* div#stallSearchItems,
  div#searchShopsOnDirectorySearch,
  div#filterByCategory {
     width: 90%;
    width: 86%;
    margin-left: 10%;
  } */

  .menu_sidebar {
    position: absolute;
    /* flex-direction: column; */
    top: 0;
    left: 0;
    /* Hide the sidebar off-screen */
    background-color: rgb(149 62 150 / 90%);
    color: #fff;
    width: 100%;
    overflow: auto;
    height: 100vh;
    padding: 25px;
    box-sizing: border-box;
    z-index: 90;
    transition: display 0.3s ease;
    font-weight: 600;
  }

  .menu_sidebar.active {
    display: block;
    /* Show the sidebar on-screen */
  }

  .menu_sidebar ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .menu_sidebar ul li {
    margin: 20px 0;
  }

  .menu_sidebar ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
  }
  .pure-form label,
  .section-divider {
    color: #fff;
  }

  .select__control,
  .select__control--is-focused {
    background-color: hsl(299 33% 46% / 1) !important;
    border-color: hsl(0deg 0% 100%) !important;
    box-shadow: 0 0 0 1px #e771e9 !important;
  }

  .select__multi-value {
    background-color: hsl(0deg 0% 100%) !important;
    color: #9e509f !important;
  }

  .select__placeholder,
  .select__indicator,
  .select__single-value {
    color: #fff !important;
  }

  .select__menu {
    width: 225px !important;
  }

  .select__menu div {
    background: #9a4c9b !important;
    color: #fff;
    width: 100%;
    overflow: auto;
  }

  .logo-icons-container {
    height: 60vh;
  }

  .logo img {
    width: 100%;
  }
  .search-dropdown-container {
    max-width: 100%;
  }

  .basic-multi-select {
    width: 300px !important;
    max-width: 100%;
  }

  .react-select__control,
  .select__control {
    width: 100% !important; /* Or set a specific width */
    max-width: 100%; /* Ensure it does not exceed its container */
  }

  .react-select__control input,
  .select__control input {
    font-size: 1rem; /* Adjust if needed */
  }

  .react-select__menu,
  .select__menu {
    width: 100% !important; /* Ensures the menu expands to full width */
    max-height: 300px; /* Adjust based on your design */
    overflow-y: auto; /* Allow scrolling if */
    overflow-x: hidden; /* Hide any overflow horizontally */
    -webkit-overflow-scrolling: touch; /* Ensures smooth scrolling on iOS */
    border: 1px solid;
  }
  .select__input {
    color: #fff !important;
  }
}
